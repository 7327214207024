import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectPushNotifications } from "../../../../store/pushNotification/repository/selectors";
import { getPushNotifications } from "../../../../store/pushNotification/useCases/getPushNotifications/actions";
import { tokens } from "../../../../locales/tokens";
import { useDebounce } from "../../../../hooks/useDebounce";
import { deletePushNotification } from "../../../../store/pushNotification/useCases/deleteNotification/actions";
import toast from "react-hot-toast";

export const useNotificationList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [filters, updateFilters] = useState({ page: 1, rowsPerPage: 30, query: '', tab: 'Ready' });
    const { notifications, count, isLoading, errorsDeletePushNotifications } = useSelector(selectPushNotifications);
    const [pageAmount, setPageAmount] = useState(0);
    const [deleteClick, setDeleteClick] = useState(false);
    const tabOptions = [
        { label: t(tokens.notification.readyForSending), value: "Ready" },
        { label: t(tokens.notification.sended), value: "Completed" },
        { label: t(tokens.notification.archive), value: "Deleted" },
    ];
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getPushNotifications(combineRequestBody()));
    }, [filters]);

    useEffect(() => {
        if (count) {
            setPageAmount(Math.ceil(count / filters.rowsPerPage));
        }
    }, [count]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (errorsDeletePushNotifications) {
                toast.error(errorsDeletePushNotifications)
            } else {
                filters.tab !== "Deleted"
                ? toast.success((t(tokens.notification.message.notificationRemoved)))
                : toast.success((t(tokens.notification.message.notificationRemovedHard)));
                dispatch(getPushNotifications(combineRequestBody()));
            }
            setDeleteClick(false);
        }
    }, [errorsDeletePushNotifications, isLoading]);

    const combineRequestBody = () => {
        const body: any = { page: filters.page, perPage: filters.rowsPerPage, status: filters.tab, title: filters.query };
        return body;
    };

    const handleSearchNotificationText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleNotificationSeacrh(e.target.value);
    };

    const onSearchNotification = (value: string) => {
        const query = value ? value : '';
        if (query.length !== 1) {
            updateFilters((prevState) => ({ ...prevState, query, page: 1 }));
            scrollTopList();
        }
    };

    const { debouncedWrapper: debouncedHandleNotificationSeacrh } = useDebounce(onSearchNotification, 1000);

    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        updateFilters((prevState) => ({ ...prevState, tab, page: 1 }));
        scrollTopList();
    };

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [updateFilters]);

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, id: number, mode?: string) => {
        e.preventDefault();

        if (!mode) {
            dispatch(deletePushNotification({ id }));
            setDeleteClick(true);
        } else {
            dispatch(deletePushNotification({ id, mode }));
            setDeleteClick(true);
        }
    };

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };
    return { searchText, handleSearchNotificationText, filters, tabOptions, handleTabsChange, handlePageChange, handleDelete, pageAmount, notifications, scrollRef }
};
