import { IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import dayjs from "dayjs";
import { tokens } from "../../../../../../../locales/tokens";
import { Link } from "react-router-dom";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface IProps {
    course: any;
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number, mode?: string) => void;
    handleUpload: (id: number, name: string) => void;
};

export const CourseTableItem: FC<IProps> = ({ handleDelete, handleStatus, handleUpload, course }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const formattedDate = dayjs(course.updatedAt).subtract(3, 'hours').format('DD.MM.YYYY');
    const changeStatus = useCallback(() => handleStatus(course.id, course.status), [course.id, course.status]);
    const deleteCourse = useCallback(() => {
        if (course.deletedAt) {
            handleDelete(course.id, 'hard');
        } else {
            handleDelete(course.id);
        }
    }, [course.id]);
    const uploadReport = useCallback(() => handleUpload(course.id, course.name), [course.id, course.name]);

    return (
        <TableRow hover key={course.id}>
            <TableCell align="left" width='50%' sx={styles.cell}>
                <Typography sx={styles.name} variant="body2">{course.name}</Typography>
            </TableCell>
            <TableCell align="center" width='25%' sx={styles.cell}>
                <Typography variant="body2">
                    {formattedDate}
                </Typography>
            </TableCell>
            <TableCell align="right" width='25%' sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <IconButton size="small" onClick={uploadReport}>
                        <Tooltip title={t(tokens.documents.uploadReport)}>
                            <SvgIcon style={styles.svgIcon}><SimCardDownloadOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!course.deletedAt &&
                        <IconButton size="small" onClick={changeStatus}>
                            {course.status === 'Hidden' ?
                                <Tooltip title={t(tokens.documents.notPublish)}>
                                    <SvgIcon style={styles.svgIcon}><VisibilityOffOutlinedIcon /></SvgIcon>
                                </Tooltip>
                                :
                                <Tooltip title={t(tokens.documents.publish)} >
                                    <SvgIcon style={styles.svgIcon}><VisibilityOutlinedIcon /></SvgIcon>
                                </Tooltip>
                            }
                        </IconButton>}
                    <IconButton size="small" component={Link} to={`/content-course-edit/${course.id}`}>
                        <Tooltip title={course.deletedAt ? t(tokens.common.watch) : t(tokens.common.edit)}>
                            <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!course.deletedAt ?
                        <IconButton size="small" onClick={deleteCourse}>
                            <Tooltip title={t(tokens.common.delete)}>
                                <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                            </Tooltip>
                        </IconButton>
                        :
                        <IconButton size="small" onClick={deleteCourse}>
                            <Tooltip title={t(tokens.common.deleteHard)}>
                                <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                            </Tooltip>
                        </IconButton>}
                </Stack>
            </TableCell>
        </TableRow>
    )
};