import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { getAppPages } from "../../../../store/appPage/useCases/getAppPages/action";
import { selectAppPage } from "../../../../store/appPage/repository/selector";
import { setAppPage, setAppPageError } from "../../../../store/appPage/repository/slice";
import { deleteAppPage } from "../../../../store/appPage/useCases/deleteAppPage/action";
import { updateAppPage } from "../../../../store/appPage/useCases/updateAppPage/action";

export const useAppPageList = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<'ARCHIVE' | 'ACTIVE'>('ACTIVE');
    const dispatch = useDispatch();
    const [isUpdateClick, setIsUpdateClick] = useState(false);
    const [isDeleteClick, setIsDeleteClick] = useState(false);
    const [isRequestList, setIsRequestList] = useState(false);
    const { appPageList, appPageCount, isAppPageLoading, appPageError } = useSelector(selectAppPage);
    
    useEffect(() => {
        setIsRequestList(true);
        dispatch(getAppPages({ type: activeTab }));
    }, [activeTab]);

    useEffect(() => {
        if (!isAppPageLoading && isUpdateClick) {
            if (appPageError) {
                toast.error(appPageError)
                dispatch(setAppPageError(null));
            } else {
                dispatch(getAppPages({ type: activeTab }));
                toast.success(t(tokens.appPage.messages.updated));
            }
            setIsUpdateClick(false);
        };
    }, [isAppPageLoading]);

    useEffect(() => {
        if (!isAppPageLoading && isDeleteClick) {
            if (appPageError) {
                toast.error(appPageError)
                dispatch(setAppPageError(null));
            } else {
                dispatch(getAppPages({ type: activeTab }));
                activeTab === "ACTIVE"
                ? toast.success(t(tokens.appPage.messages.removed))
                : toast.success(t(tokens.appPage.messages.removedHard));
            }
            setIsDeleteClick(false);
        };
    }, [isAppPageLoading]);

    useEffect(() => {
        if (!isAppPageLoading && isRequestList) {
            if (appPageError) {
                toast.error(appPageError)
                dispatch(setAppPageError(null));
            }
            setIsRequestList(false);
        };
    }, [isAppPageLoading]);

    const onToggleTab = () => {
        setIsRequestList(true);
        setActiveTab(prev => prev === 'ACTIVE' ? 'ARCHIVE' : 'ACTIVE');
    };

    const onChangeVisibilityPage = (appPageId: number, isHidden: boolean) => {
        if (appPageId) {
            setIsUpdateClick(true);
            // const updatedPages = appPageList.map(page => page.id === appPageId ? { ...page, isHidden } : page);
            // dispatch(setAppPage({ rows: updatedPages, count: appPageCount }));
            dispatch(updateAppPage({ id: Number(appPageId), isHidden }));
        } else {
            toast.error('Something went wrong');
        };
    };

    const onDeleteAppPage = (appPageId: number, mode?: string) => {
        if (appPageId && !mode) {
            setIsDeleteClick(true);
            dispatch(deleteAppPage({ id: Number(appPageId) }));
        } else {
            setIsDeleteClick(true);
            dispatch(deleteAppPage({ id: Number(appPageId), mode }));
        };
    };

    return { activeTab, appPageList, isAppPageLoading, isRequestList, onToggleTab, onDeleteAppPage, onChangeVisibilityPage };
};