import { all, fork } from "redux-saga/effects";
import { SignInWatcher } from "./auth/useCases/signIn/worker";
import { recoverPasswordWatcher } from "./auth/useCases/recoverPassword/worker";
import { changePasswordWatcher } from "./auth/useCases/changePassword/worker";
import { getDocumentsWatcher } from "./document/useCases/getDocuments/worker";
import { getCategoriesWatcher } from "./category/useCases/getCategories/worker";
import { getUsersWatcher } from "./user/useCases/getUsers/worker";
import { deleteDocumentWatcher } from "./document/useCases/deleteDocument/worker";
import { updateDocumentWatcher } from "./document/useCases/updateDocument/worker";
import { updateCategoryWatcher } from "./category/useCases/updateCategory/worker";
import { createCategoryWatcher } from "./category/useCases/createCategory/worker";
import { deleteAdvertWatcher } from "./advert/useCases/deleteAdvert/worker";
import { getAdvertsWatcher } from "./advert/useCases/getAdverts/worker";
import { updateAdvertWatcher } from "./advert/useCases/updateAdvert/worker";
import { uploadReportWatcher } from "./document/useCases/uploadReport/worker";
import { getOrgStructureWatcher } from "./orgStructure/useCases/getOrgStructure/worker";
import { getFileWatcher } from "./file/useCases/getFile/worker";
import { updateOrgStructureWatcher } from "./orgStructure/useCases/updateOrgStructure/worker";
import { getSingleUserWatcher } from "./user/useCases/getSingleUser/worker";
import { getRolesWatcher } from "./role/useCases/getRoles/worker";
import { setUsersRoleWatcher } from "./user/useCases/setUsersRole/worker";
import { deleteUsersRoleWatcher } from "./user/useCases/deleteUserRole/worker";
import { uploadAdvertReportWatcher } from "./advert/useCases/uploadReport/worker";
import { addFileToCategoryWatcher } from "./category/useCases/addFileToCategory/worker";
import { getSingleCategoryWatcher } from "./category/useCases/getSingleCategory/worker";
import { createDocumentWatcher } from "./document/useCases/createDocument/worker";
import { putDocumentInCategoryWatcher } from "./document/useCases/putDocumentInCategory/worker";
import { createAdvertWatcher } from "./advert/useCases/createAdvert/worker";
import { getSingleAdvertWatcher } from "./advert/useCases/getSingleAdvert/worker";
import { deleteFileWatcher } from "./file/useCases/deleteFile/worker";
import { addImageWatcher } from "./advert/useCases/addImage/worker";
import { getBackupListWatcher } from "./backup/useCases/getBackupList/worker";
import { restoreDataWatcher } from "./backup/useCases/restoreData/worker";
import { createBackupWatcher } from "./backup/useCases/createBackup/worker";
import { getSingleDocumentWatcher } from "./document/useCases/getSingleDocument/worker";
import { addFilesWatcher } from "./document/useCases/addFiles/worker";
import { getTestListWatcher } from "./test/useCases/getTestList/worker";
import { updateTestWatcher } from "./test/useCases/updateTest/worker";
import { deleteTestWatcher } from "./test/useCases/deleteTest/worker";
import { uploadTestReportWatcher } from "./test/useCases/uploadTestReport/worker";
import { getSingleTestWatcher } from "./test/useCases/getSingleTest/worker";
import { createTestWatcher } from "./test/useCases/createTest/worker";
import { getBadgesWatcher } from "./badge/useCases/getBadges/worker";
import { createQuestionWatcher } from "./test/useCases/createQuestion/worker";
import { createAnswerWatcher } from "./test/useCases/createAnswer/worker";
import { updateQuestionWatcher } from "./test/useCases/updateQuestion/worker";
import { deleteQuestionWatcher } from "./test/useCases/deleteQuestion/worker";
import { deleteAnswerWatcher } from "./test/useCases/deleteAnswer/worker";
import { updateAnswerWatcher } from "./test/useCases/updateAnswer/worker";
import { questionAddImageWatcher } from "./test/useCases/questionAddImage/worker";
import { deleteFileFromQuestionWatcher } from "./test/useCases/deleteFileFromQuestion/worker";
import { updateDocumentFilesWatcher } from "./document/useCases/updateFiles/worker";
import { deleteDocumentFilesWatcher } from "./document/useCases/deleteFiles/worker";
import { getParentCategoriesWatcher } from "./category/useCases/getParentCategories/worker";
import { createRoleWatcher } from "./role/useCases/createRole/worker";
import { getRoleByIdWatcher } from "./role/useCases/getRoleById/worker";
import { updateRoleWatcher } from "./role/useCases/updateRole/worker";
import { deleteRoleWatcher } from "./role/useCases/deleteRole/worker";
import { getPermissionsWatcher } from "./permission/useCases/getPermissions/worker";
import { addRolePermissionWatcher } from "./permission/useCases/addRolePermission/worker";
import { deleteRolePermissionWatcher } from "./permission/useCases/deleteRolePermission/worker";
import { getAppPagesWatcher } from "./appPage/useCases/getAppPages/worker";
import { getAppPageByIdWatcher } from "./appPage/useCases/getAppPageById/worker";
import { updateAppPageWatcher } from "./appPage/useCases/updateAppPage/worker";
import { createAppPageWatcher } from "./appPage/useCases/createAppPage/worker";
import { deleteAppPageWatcher } from "./appPage/useCases/deleteAppPage/worker";
import { getPushNotificationsWatcher } from "./pushNotification/useCases/getPushNotifications/worker";
import { createPushNotificationWatcher } from "./pushNotification/useCases/createNotification/worker";
import { deletePushNotificationWatcher } from "./pushNotification/useCases/deleteNotification/worker";
import { getNotificationByIdWatcher } from "./pushNotification/useCases/getNotificationById/worker";
import { updateNotificationWatcher } from "./pushNotification/useCases/updateNotification/worker";
import { sendNotificationWatcher } from "./pushNotification/useCases/sendNotification/worker";
import { getProductsWatcher } from "./product/useCases/getProducts/worker";
import { createProductWatcher } from "./product/useCases/createProduct/worker";
import { getProductByIdWatcher } from "./product/useCases/getProductById/worker";
import { updateProductWatcher } from "./product/useCases/updateProduct/worker";
import { deleteProductWatcher } from "./product/useCases/deleteProduct/worker";
import { deleteQuizWatcher } from "./quiz/useCases/deleteQuiz/worker";
import { getQuizListWatcher } from "./quiz/useCases/getQuizList/worker";
import { updateQuizWatcher } from "./quiz/useCases/updateQuiz/worker";
import { getSingleQuizWatcher } from "./quiz/useCases/getSingleQuiz/worker";
import { createQuizWatcher } from "./quiz/useCases/createQuiz/worker";
import { uploadQuizReportWatcher } from "./quiz/useCases/uploadQuizReport/worker";
import { getOrderWatcher } from "./order/useCases/getOrders/worker";
import { updateOrderStatusWatcher } from "./order/useCases/updateOrderStatus/worker";
import { deleteProductImageWatcher } from "./product/useCases/deleteProductImage/worker";
import { addQuizQuestionImageWatcher } from "./quiz/useCases/questionAddImage/worker";
import { createQuizQuestionWatcher } from "./quiz/useCases/createQuestion/worker";
import { updateQuizQuestionWatcher } from "./quiz/useCases/updateQuestion/worker";
import { deleteQuizQuestionWatcher } from "./quiz/useCases/deleteQuestion/worker";
import { deleteFileFromQuizQuestionWatcher } from "./quiz/useCases/deleteFileFromQuestion/worker";
import { getCourseListWatcher } from "./course/useCases/getCourseList/worker";
import { deleteCourseWatcher } from "./course/useCases/deleteCourse/worker";
import { updateCourseWatcher } from "./course/useCases/updateCourse/worker";
import { createCourseWatcher } from "./course/useCases/createCourse/worker";
import { getSingleCourseWatcher } from "./course/useCases/getSingleCourse/worker";
import { uploadCourseReportWatcher } from "./course/useCases/uploadCourseReport/worker";
import { createTaskWatcher } from "./course/useCases/createTask/worker";
import { updateTaskWatcher } from "./course/useCases/updateTask/worker";
import { deleteTaskWatcher } from "./course/useCases/deleteTask/worker";
import { getLearningHistoryWatcher } from "./learningHistory/useCases/getLearningHistory/worker";
import { getUserOrderWatcher } from "./order/useCases/getUserOrder/worker";
import { getGeneralQuestionListWatcher } from "./generalQuestion/useCases/getGeneralQuestionList/worker";
import { updateGeneralQuestionWatcher } from "./generalQuestion/useCases/updateGeneralQuestion/worker";
import { getIdeaListWatcher } from "./isIdea/useCases/getIdeaList/worker";
import { updateIdeaWatcher } from "./isIdea/useCases/updateIdea/worker";
import { getAnonymousComplaintListWatcher } from "./anonymousComplaint/useCases/getAnonymousComplaintList/worker";
import { updateAnonymousComplaintWatcher } from "./anonymousComplaint/useCases/updateAnonymousComplaint/worker";
import { getUsersActivityWatcher } from "./analytics/useCases/getUsersActivity/worker";
import { getTestResultsWatcher } from "./analytics/useCases/getTestResults/worker";
import { getCourseResultsWatcher } from "./analytics/useCases/getCourseResults/worker";
import { getSearchRequestsWatcher } from "./analytics/useCases/getSearchRequests/worker";
import { getSectionFrequencyWatcher } from "./analytics/useCases/getSectionFrequency/worker";
import { getProductsRateWatcher } from "./analytics/useCases/getProductsRate/worker";
import { getTimeContentWatcher } from "./analytics/useCases/getTimeContent/worker";
import { getTimeAdvertWatcher } from "./analytics/useCases/getTimeAdvert/worker";
import { updateUserWatcher } from "./user/useCases/updateUser/worker";
import { getLeaderBoardDataWatcher } from "./analytics/useCases/getLeaderBoardData/worker";
import { getSingleTestDataWatcher } from "./analytics/useCases/getSingleTestData/worker";
import { addFilesToProductWatcher } from "./product/useCases/addFiles/worker";
import { updateFilesInProductWatcher } from "./product/useCases/updateFiles/worker";
import { getSingleCourseDataWatcher } from "./analytics/useCases/getSingleCourseData/worker";
import { getSingleDocumentDataWatcher } from "./analytics/useCases/getSingleDocumentData/worker";
import { getSingleAdvertDataWatcher } from "./analytics/useCases/getSingleAdvertData/worker";
import { getTraineePositionsWatcher } from "./trainee/useCases/getTraineePositions/worker";
import { createTraineeTaskWatcher } from "./trainee/useCases/createTraineeTask/worker";
import { getTraineePositionByIdWatcher } from "./trainee/useCases/getTraineePositionById/worker";
import { updateTraineeTaskWatcher } from "./trainee/useCases/updateTraineeTask/worker";
import { deleteTraineeTaskWatcher } from "./trainee/useCases/deleteTraineeTask/worker";
import { getAccessListWatcher } from "./access/useCases/getAccessList/worker";
import { deleteAccessWatcher } from "./access/useCases/deleteAccess/worker";
import { getAssignListWatcher } from "./assign/useCases/getAssignList/worker";
import { deleteAssignWatcher } from "./assign/useCases/deleteAssign/worker";
import { getPositionsWatcher } from "./user/useCases/getPositions/worker";
import { getAssignListBadgeWatcher } from "./badge/useCases/getAssignList/worker";
import { deleteAssignBadgeWatcher } from "./badge/useCases/deleteAssign/worker";
import { createTraineePositionWatcher } from "./trainee/useCases/createTraineePosition/worker";
import { deleteTraineePositionWatcher } from "./trainee/useCases/deleteTraineePosition/worker";
import { deleteAllAccessWatcher } from "./access/useCases/deleteAllAccess/worker";
import { removeAllAssignWatcher } from "./assign/useCases/deleteAllAssign/worker";
import { getCategoriesWithoutTreeWatcher } from "./category/useCases/getCategoriesWithoutTree/worker";
import { deleteDocumentHardWatcher } from "./document/useCases/deleteDocumentHard/worker";
import { deleteTestHardWatcher } from "./test/useCases/deleteTestHard/worker";

export function* rootSaga() {
    yield all([
        //----------------AUTH----------------------
        fork(SignInWatcher),
        fork(recoverPasswordWatcher),
        fork(changePasswordWatcher),
        //----------------DOCUMENTS----------------------
        fork(getDocumentsWatcher),
        fork(updateDocumentWatcher),
        fork(deleteDocumentWatcher),
        fork(createDocumentWatcher),
        fork(getSingleDocumentWatcher),
        fork(updateDocumentFilesWatcher),
        fork(deleteDocumentFilesWatcher),
        fork(deleteDocumentHardWatcher),
        //----------------CATEGORIES----------------------
        fork(getCategoriesWatcher),
        fork(updateCategoryWatcher),
        fork(createCategoryWatcher),
        fork(addFileToCategoryWatcher),
        fork(getSingleCategoryWatcher),
        fork(putDocumentInCategoryWatcher),
        fork(getParentCategoriesWatcher),
        fork(getCategoriesWithoutTreeWatcher),
        //----------------USER----------------------
        fork(getUsersWatcher),
        fork(getSingleUserWatcher),
        fork(setUsersRoleWatcher),
        fork(deleteUsersRoleWatcher),
        fork(getLearningHistoryWatcher),
        fork(updateUserWatcher),
        fork(getPositionsWatcher),
        //----------------ADVERT----------------------
        fork(getAdvertsWatcher),
        fork(updateAdvertWatcher),
        fork(deleteAdvertWatcher),
        fork(uploadAdvertReportWatcher),
        fork(createAdvertWatcher),
        fork(getSingleAdvertWatcher),
        //----------------TEST----------------------
        fork(getTestListWatcher),
        fork(updateTestWatcher),
        fork(deleteTestWatcher),
        fork(uploadTestReportWatcher),
        fork(getSingleTestWatcher),
        fork(createTestWatcher),
        fork(createQuestionWatcher),
        fork(createAnswerWatcher),
        fork(updateQuestionWatcher),
        fork(deleteQuestionWatcher),
        fork(deleteAnswerWatcher),
        fork(updateAnswerWatcher),
        fork(questionAddImageWatcher),
        fork(deleteFileFromQuestionWatcher),
        fork(deleteTestHardWatcher),
        //----------------ROLES----------------------
        fork(getRolesWatcher),
        fork(createRoleWatcher),
        fork(getRoleByIdWatcher),
        fork(updateRoleWatcher),
        fork(deleteRoleWatcher),
        //----------------PERMISSIONS----------------------
        fork(getPermissionsWatcher),
        fork(addRolePermissionWatcher),
        fork(deleteRolePermissionWatcher),
        //----------------ORG_STRUCTURE----------------------
        fork(updateOrgStructureWatcher),
        fork(getOrgStructureWatcher),
        //----------------FILES-IMAGES----------------------
        fork(getFileWatcher),
        fork(deleteFileWatcher),
        fork(addFilesWatcher),
        fork(addImageWatcher),
        //----------------BADGES----------------------
        fork(getBadgesWatcher),
        fork(getAssignListBadgeWatcher),
        fork(deleteAssignBadgeWatcher),
        //----------------REPORT----------------------
        fork(uploadReportWatcher),
        //----------------BACKUP----------------------
        fork(getBackupListWatcher),
        fork(restoreDataWatcher),
        fork(createBackupWatcher),
        //----------------APP_PAGES----------------------
        fork(getAppPagesWatcher),
        fork(createAppPageWatcher),
        fork(getAppPageByIdWatcher),
        fork(updateAppPageWatcher),
        fork(deleteAppPageWatcher),
        //---------------NOTIFICTIONS--------------------
        fork(getPushNotificationsWatcher),
        fork(createPushNotificationWatcher),
        fork(deletePushNotificationWatcher),
        fork(getNotificationByIdWatcher),
        fork(updateNotificationWatcher),
        fork(sendNotificationWatcher),
        //----------------PRODUCTS----------------------
        fork(getProductsWatcher),
        fork(createProductWatcher),
        fork(getProductByIdWatcher),
        fork(updateProductWatcher),
        fork(deleteProductWatcher),
        fork(addFilesToProductWatcher),
        fork(updateFilesInProductWatcher),
        //---------------QUIZ---------------------------
        fork(getQuizListWatcher),
        fork(deleteQuizWatcher),
        fork(updateQuizWatcher),
        fork(getSingleQuizWatcher),
        fork(createQuizWatcher),
        fork(uploadQuizReportWatcher),
        fork(addQuizQuestionImageWatcher),
        fork(createQuizQuestionWatcher),
        fork(updateQuizQuestionWatcher),
        fork(deleteQuizQuestionWatcher),
        fork(deleteFileFromQuizQuestionWatcher),
        //---------------ORDER-----------------------
        fork(getOrderWatcher),
        fork(updateOrderStatusWatcher),
        fork(deleteProductImageWatcher),
        fork(getUserOrderWatcher),
        //---------------COURSE-----------------------
        fork(getCourseListWatcher),
        fork(deleteCourseWatcher),
        fork(updateCourseWatcher),
        fork(createCourseWatcher),
        fork(getSingleCourseWatcher),
        fork(uploadCourseReportWatcher),
        fork(createTaskWatcher),
        fork(updateTaskWatcher),
        fork(deleteTaskWatcher),
        //------------GENERAL QUESTIONS---------------
        fork(getGeneralQuestionListWatcher),
        fork(updateGeneralQuestionWatcher),
        fork(getIdeaListWatcher),
        fork(updateIdeaWatcher),
        fork(getAnonymousComplaintListWatcher),
        fork(updateAnonymousComplaintWatcher),
        //--------------ANALYTICS---------------------
        fork(getUsersActivityWatcher),
        fork(getTestResultsWatcher),
        fork(getCourseResultsWatcher),
        fork(getSearchRequestsWatcher),
        fork(getSectionFrequencyWatcher),
        fork(getProductsRateWatcher),
        fork(getTimeContentWatcher),
        fork(getTimeAdvertWatcher),
        fork(getLeaderBoardDataWatcher),
        fork(getSingleTestDataWatcher),
        fork(getSingleCourseDataWatcher),
        fork(getSingleDocumentDataWatcher),
        fork(getSingleAdvertDataWatcher),
        //--------------TRAINEE------------------------
        fork(getTraineePositionsWatcher),
        fork(getTraineePositionByIdWatcher),
        fork(createTraineeTaskWatcher),
        fork(updateTraineeTaskWatcher),
        fork(deleteTraineeTaskWatcher),
        fork(createTraineePositionWatcher),
        fork(deleteTraineePositionWatcher),
        //--------------ACCESS-----------------------
        fork(getAccessListWatcher),
        fork(deleteAccessWatcher),
        fork(deleteAllAccessWatcher),
        //---------------ASSIGN----------------------
        fork(getAssignListWatcher),
        fork(deleteAssignWatcher),
        fork(removeAllAssignWatcher),

    ])
};