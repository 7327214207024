import { FC, RefObject, forwardRef, useMemo } from "react";
import { TreeSelect } from 'antd';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { getStyles } from "./styles";
import { Box, useTheme } from "@mui/material";

interface IProps {
    size?: string;
    title: string;
    value?: any | null;
    optionList: any[];
    isValid?: boolean;
    isDisabled?: boolean;
    onSelect?: (selectedKeysValue: string, node: any) => void;
    onChange?: () => void;
};

export const CheckboxAutocompleteSingle: FC<IProps> = forwardRef<RefObject<any>, IProps>(({ title, value, onSelect, optionList, onChange, size, isDisabled }, ref: any) => {
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    const styles = useMemo(() => getStyles(isDark, size), [isDark, size]); 

    const transformData = (data: any[], parentKey: string) => {
        return data.map((item, index) => {
            const key = parentKey ? `${parentKey}-${index}-${item.id}` : `${index}-${item.id}`;
            const { name, children, departmentGuid, id, first_name, last_name, position, patronymic } = item;

            const useDepartmentGuid = process.env.NODE_ENV === "production";
            
            const treeNode: any = {
                label: name || (last_name + " " + first_name + " " + patronymic + " " + `(${position})`),
                value: departmentGuid || id
                // value: !useDepartmentGuid ? departmentGuid || id : id,
            };

            if ('children' in item) {
                treeNode.children = transformData(children, key);
            };

            return treeNode;
        });
    };

    const transformedData = transformData(optionList, '');

    const tProps = {
        treeData: transformedData,
        value,
        disabled: isDisabled,
        onSelect,
        onChange,
        showSearch: true,
        treeIcon: false,
        treeCheckable: false,
        placeholder: title,
        suffixIcon: !value ? <ArrowDropDownIcon sx={styles.suffixIcon} /> : null,
        allowClear: { clearIcon: <ClearIcon sx={styles.clearIcon} fontSize="small" /> },
        style: { width: '100%', height: '40px' },
        filterTreeNode: (inputValue: string, treeNode: any) =>
            treeNode.label.toLowerCase().includes(inputValue.toLowerCase()),
    };

    return (
        <Box sx={styles.wrapper}>
            <TreeSelect ref={ref} {...tProps}/>
        </Box>
    );
});
