import { Add as AddIcon, DeleteForever as DeleteForeverIcon, EditOutlined as EditOutlinedIcon, Preview as PreviewIcon } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Pagination, Stack, SvgIcon, Table, TableBody, TableCell, TableContainer, TableRow, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { FC, useMemo } from 'react';
import { Link } from "react-router-dom";
import { tokens } from '../../../../locales/tokens';
import { paths } from '../../../../paths';
import { TabSelector } from '../../../../ui-kit/tabSelector/tabSelector';
import { EmptyPage } from '../../../content/category/categoryEmpty/ui';
import { useBadges } from '../presenters/useBadges';
import { getStyles } from './styles';

const Badges: FC = () => {
    // const { buttonCreate, tableContainer, emptyStateWrapper, cell } = getStyles();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown])
    const { t, tab, tabOptions, handleTabsChange, isLoadingGetBadges, rowsBadges, isArchive, isLoadingDeleteBadges, deleteBadge, countBadges, filterBadges, handleChangePageBadge, scrollRef } = useBadges();

    return (
        <Stack p={2} pb={!(countBadges >= filterBadges.perPage) ? 4 : undefined}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h5" fontWeight={600}>
                    {t(tokens.badge.badges.title)}
                </Typography>
                <Button startIcon={(<SvgIcon><AddIcon /></SvgIcon>)} variant="contained" component={Link} to={paths.dashboard.badge.create} sx={styles.buttonCreate}>
                    {t(tokens.badge.badges.buttonCreate)}
                </Button>
            </Stack>
            <Stack mt={1}><TabSelector tab={tab} tabOptions={tabOptions} handleTabsChange={handleTabsChange} /></Stack>
            <TableContainer>
                <Box sx={styles.tableWrapper} ref={scrollRef}>
                    <Table>
                        <TableBody>
                            {isLoadingGetBadges
                                ? <TableRow sx={styles.tableContainer}>
                                    <TableCell align='center' sx={styles.cell}>
                                        <CircularProgress color="primary" />
                                    </TableCell>
                                </TableRow>
                                : rowsBadges.length > 0
                                    ? rowsBadges?.map(({ image, points, name, id }: any) => (
                                        <TableRow key={id} hover>
                                            <TableCell align="left" width='50%' sx={styles.cell}>
                                                <Stack direction="row" alignItems="center" spacing={2}>
                                                    <Box component="img" src={image?.url} width={45} height={45} alt="advert_logo" style={{ objectFit: 'cover' }} />
                                                    <Typography>{name}</Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="center" width='10%' sx={styles.cell}>
                                                <Typography>{points ? points : 0} балів</Typography>
                                            </TableCell>
                                            <TableCell align="right" width='20%' sx={styles.cell}>
                                                <Stack flexDirection={"row"} justifyContent={"end"}>
                                                    <Tooltip title={isArchive ? "Переглянути" : t(tokens.badge.tooltip.edit)}>
                                                        <Link to={isArchive
                                                            ? {
                                                                pathname: `/badges/${id}`,
                                                                search: "view"
                                                            }
                                                            : `/badges/${id}/edit`}>
                                                            <IconButton>
                                                                <SvgIcon sx={{ width: 26, height: 26 }}>
                                                                    <EditOutlinedIcon />
                                                                </SvgIcon>
                                                            </IconButton>
                                                        </Link>
                                                    </Tooltip>
                                                    {!isArchive
                                                        ? <Tooltip title={t(tokens.badge.tooltip.delete)}>
                                                            <IconButton disabled={isLoadingDeleteBadges} onClick={() => deleteBadge({id})}>
                                                                <SvgIcon sx={{ width: 26, height: 26 }}>
                                                                    <DeleteForeverIcon />
                                                                </SvgIcon>
                                                            </IconButton>
                                                        </Tooltip>
                                                        : <Tooltip title={t(tokens.badge.tooltip.deleteHard)}>
                                                            <IconButton disabled={isLoadingDeleteBadges} onClick={() => deleteBadge({id, mode: 'hard'})}>
                                                                <SvgIcon sx={{ width: 26, height: 26 }}>
                                                                    <DeleteForeverIcon />
                                                                </SvgIcon>
                                                            </IconButton>
                                                        </Tooltip>}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>))
                                    : <Box sx={{ ...styles.tableContainer, ...styles.emptyStateWrapper }}>
                                        <EmptyPage title='Список порожній' description='Тут буде відображено список бейджів, але зараз він порожній. Натисніть кнопку “Створити” або змініть пошуковий запит.' />
                                    </Box>}
                        </TableBody>
                    </Table>
                </Box>
            </TableContainer>
            {countBadges >= filterBadges.perPage
                && <Stack py={4} sx={styles.paginationWrapper}>
                    <Pagination sx={{ alignSelf: "center" }} page={filterBadges.page} count={Math.ceil(countBadges / filterBadges.perPage)} color="primary" onChange={handleChangePageBadge} />
                </Stack>}
        </Stack >
    );
};

export default Badges;