import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AdvertFilters } from "../../../../types/advertList";
import { TIMESTAMP_DAY } from "../../../../config";
import { getAdverts } from "../../../../store/advert/useCases/getAdverts/action";
import { selectAdvert } from "../../../../store/advert/repository/selector";
import { useDebounce } from "../../../../hooks/useDebounce";
import toast from "react-hot-toast";
import { updateAdvert } from "../../../../store/advert/useCases/updateAdvert/action";
import { deleteAdvert } from "../../../../store/advert/useCases/deleteAdvert/action";
import { tokens } from "../../../../locales/tokens";
import { selectOrgStructure } from "../../../../store/orgStructure/repository/selector";
import { updateOrgStructure } from "../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { uploadAdvertReport } from "../../../../store/advert/useCases/uploadReport/action";

export const useAdvertList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [filters, updateFilters] = useState<AdvertFilters>({
        page: 1, rowsPerPage: 30, query: '', tab: 'Active', type: null, status: null, initiator: null, date: null
    });
    const { advertList, advertsCount, isLoading, deleteAdvertError, updateAdvertError } = useSelector(selectAdvert);
    const [pageAmount, setPageAmount] = useState(0);
    const [updateClick, setUpdateClick] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);
    const { orgStructure } = useSelector(selectOrgStructure);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getAdverts(combineRequestBody()));
    }, [filters]);

    const combineRequestBody = () => {
        const body: any = { page: filters.page, perPage: filters.rowsPerPage };
        if (filters.query) body.theme = filters.query
        if (filters.initiator) body.initiatorId = filters.initiator.value;
        if (filters.type) body.type = filters.type.value;
        if (filters.status) body.status = filters.status.value;
        if (filters.date) {
            const startDateString = new Date(filters.date).toISOString();
            body.updatedAtStart = startDateString;
            const endDateString = new Date((filters.date) + TIMESTAMP_DAY).toISOString();
            body.updatedAtEnd = endDateString;
        };
        body.isDeleted = filters.tab === 'Active' ? false : true;
        return body;
    };

    useEffect(() => {
        dispatch(updateOrgStructure());
    }, []);

    useEffect(() => {
        if (advertsCount) {
            setPageAmount(Math.ceil(advertsCount / filters.rowsPerPage));
        }
    }, [advertsCount]);

    useEffect(() => {
        debouncedHandleAdvertSeacrh(searchText);
    }, [searchText]);

    useEffect(() => {
        if (!isLoading && updateClick) {
            if (updateAdvertError) {
                toast.error(updateAdvertError)
            } else {
                toast.success(t(tokens.adverts.messages.advertUpdated));
                dispatch(getAdverts(combineRequestBody()));
            }
            setUpdateClick(false);
        }
    }, [updateAdvertError, isLoading]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (deleteAdvertError) {
                toast.error(deleteAdvertError)
            } else {
                filters.tab === "Active" 
                ? toast.success((t(tokens.adverts.messages.advertRemoved)))
                : toast.success((t(tokens.adverts.messages.advertRemovedHard)));
                dispatch(getAdverts(combineRequestBody()));
            }
            setDeleteClick(false);
        }
    }, [deleteAdvertError, isLoading]);

    const handleSearchAdvertText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onSearchAdvert = (value: string) => {
        const query = value ? value : '';
        if (query.length !== 1) {
            updateFilters((prevState) => ({ ...prevState, query, page: 1 }));
            scrollTopList();
        }
    };

    const { debouncedWrapper: debouncedHandleAdvertSeacrh } = useDebounce(onSearchAdvert, 400);

    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        updateFilters((prevState) => ({ ...prevState, tab, page: 1 }));
        scrollTopList();
    };

    const changeType = useCallback((type: any) => {
        updateFilters((prevState) => ({ ...prevState, page: 1, type }));
        scrollTopList();
    }, [updateFilters]);

    const changeStatus = useCallback((status: any) => {
        updateFilters((prevState) => ({ ...prevState, page: 1, status }));
        scrollTopList();
    }, [updateFilters]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [updateFilters]);

    const handleDateChange = useCallback((date: Date | null): void => {
        const currentDate = date?.valueOf() || null;
        updateFilters((prevState) => ({ ...prevState, page: 1, date: currentDate }));
        scrollTopList();
    }, [updateFilters]);

    const resetFilters = useCallback((): void => {
        updateFilters((prevState) => ({ ...prevState, page: 1, rowsPerPage: 30, type: null, status: null, initiator: null, date: null }));
        scrollTopList();
    }, [updateFilters]);

    const handleStatus = (id: number, status: string) => {
        const advertStatus = status === 'Hidden' ? 'Available' : 'Hidden';
        dispatch(updateAdvert({ body: { id, status: advertStatus } }));
        setUpdateClick(true);
    };

    const handleDelete = (id: number, mode?: string) => {
        if (!mode) {
            dispatch(deleteAdvert({ id }));
            setDeleteClick(true);
        } else {
            dispatch(deleteAdvert({ id, mode }));
            setDeleteClick(true);
        }
    };

    const handleUploadReport = (id: number, name: string) => {
        dispatch(uploadAdvertReport({ id, name }));
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        updateFilters((prevState) => ({ ...prevState, page: 1, initiator: node }));
        scrollTopList();
    };

    const onChangeInitiator = () => {
        if (filters.initiator) {
            updateFilters((prevState) => ({ ...prevState, page: 1, initiator: null }));
        }
    };

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    return {
        filters, searchText, handleTabsChange, handleSearchAdvertText, changeType, changeStatus, handlePageChange,
        advertList, orgStructure, handleDateChange, pageAmount, onSelectInitiator, onChangeInitiator,
        handleStatus, resetFilters, handleDelete, handleUploadReport, scrollRef
    }
};