import { FC, RefObject, forwardRef, useMemo } from "react";
import { TreeSelect } from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import './styles.css';
import { Box, useTheme } from "@mui/material";
import { getStyles } from "./styles";

interface IProps {
    title: string;
    value?: any | null;
    optionList: any[];
    isDisabled?: boolean;
    onChange?: (newValue: string[]) => void;
};

export const CheckboxAutocompleteMultiple: FC<IProps> = forwardRef<RefObject<any>, IProps>(({ title, value, onChange, optionList, isDisabled }, ref: any) => {
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    const styles = useMemo(() => getStyles(isDark), [isDark]);
    
    const transformData = (data: any[], parentKey: string) => {
        return data.map((item, index) => {
            const key = parentKey ? `${parentKey}-${index}` : `${index}`;
            const { name, children, departmentGuid, id, first_name, last_name, position, patronymic } = item;

            const useDepartmentGuid = process.env.NODE_ENV === "production";

            const treeNode: any = {
                label: name || (last_name + " " + first_name + " " + patronymic + " " + `(${position})`),
                value: departmentGuid || id
                // value: !useDepartmentGuid ? departmentGuid || id : id,
            };

            if ('children' in item) {
                treeNode.children = transformData(children, key);
            };

            return treeNode;
        });
    }; 


    const transformedData = transformData(optionList, '');

    const onChangePermission = (newValue: string[]) => {
        const leafNodeIds = getLeafNodeIds(transformedData);

        const filteredIds = newValue.filter((id) => leafNodeIds.includes(id));
        onChange && onChange(filteredIds);
    }; 

    const getLeafNodeIds = (data: any[]): string[] => {
        const ids: string[] = [];
        data.forEach((item) => {
            if (!item.children) {
                ids.push(item.value);
            } else {
                ids.push(...getLeafNodeIds(item.children));
            }
        });
        return ids;
    };

    const tProps = {
        treeData: transformedData,
        value,
        disabled: isDisabled,
        onChange: onChangePermission,
        showSearch: true,
        treeIcon: false,
        treeCheckable: true,
        placeholder: title,
        suffixIcon: <SearchIcon sx={styles.suffixIcon} />,
        style: { width: '100%' },
        filterTreeNode: (inputValue: string, treeNode: any) =>
            treeNode.label?.toLowerCase().includes(inputValue.toLowerCase())
    };

    return (
        <Box sx={styles.wrapper}>
            <TreeSelect ref={ref} {...tProps} />
        </Box>
    );
});