import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../../../store/document/useCases/getDocuments/action";
import { selectDocument } from "../../../../../store/document/repository/selector";
import { getCategories } from "../../../../../store/category/useCases/getCategories/action";
import { selectCategory } from "../../../../../store/category/repository/selector";
import { DocumentFilters } from "../../../../../types/documentList";
import { deleteDocument } from "../../../../../store/document/useCases/deleteDocument/action";
import { updateDocument } from "../../../../../store/document/useCases/updateDocument/action";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { TIMESTAMP_DAY } from "../../../../../config";
import { uploadReport } from "../../../../../store/document/useCases/uploadReport/action";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { setDocuments } from "../../../../../store/document/repository/slice";
import { getCategoriesWithoutTree } from "../../../../../store/category/useCases/getCategoriesWithoutTree/action";
import { deleteDocumentHard } from "../../../../../store/document/useCases/deleteDocumentHard/action";

export const useDocumentsList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [filters, updateFilters] = useState<DocumentFilters>({
        page: 1, rowsPerPage: 30, query: '', tab: 'Active', category: null, type: null, status: null, initiator: null, date: null
    });
    const { documentsList, documentsCount, isLoading, deleteDocumentError, updateDocumentError, getDocumentError } = useSelector(selectDocument);
    const [pageAmount, setPageAmount] = useState(0);
    const { categoryList } = useSelector(selectCategory);
    const { orgStructure } = useSelector(selectOrgStructure);
    const [updateClick, setUpdateClick] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);
    const [documentId, setDocumentId] = useState(0);
    const [isProccessDocument, setProcessDocuments] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getDocuments(combineRequestBody()));
        setProcessDocuments(true);
    }, [filters]);

    const combineRequestBody = () => {
        const body: any = { page: filters.page, perPage: filters.rowsPerPage, name: filters.query };
        if (filters.category) body.categoryId = filters.category.id;
        if (filters.initiator) body.initiatorId = filters.initiator.value;
        if (filters.type) body.type = filters.type.value;
        if (filters.status) body.status = filters.status.value;
        if (filters.date) {
            const startDateString = new Date(filters.date).toISOString();
            body.updatedAtStart = startDateString;
            const endDateString = new Date((filters.date) + TIMESTAMP_DAY).toISOString();
            body.updatedAtEnd = endDateString;
        };
        body.isDeleted = filters.tab === 'Active' ? false : true;
        return body;
    };

    useEffect(() => {
        // dispatch(getCategories({ isHidden: false }));
        dispatch(getCategoriesWithoutTree());
        dispatch(updateOrgStructure());
    }, []);

    useEffect(() => {
        if (documentsCount) {
            setPageAmount(Math.ceil(documentsCount / filters.rowsPerPage));
        }
    }, [documentsCount]);

    useEffect(() => {
        debouncedHandleDocumentSeacrh(searchText);
    }, [searchText]);

    useEffect(() => {
        if (!isLoading && isProccessDocument) {
            if (getDocumentError) {
                toast.error(getDocumentError)
            };
            setTimeout(() => setProcessDocuments(false), 400);
        }
    }, [getDocumentError, isLoading]);

    useEffect(() => {
        if (!isLoading && updateClick) {
            if (updateDocumentError) {
                toast.error(updateDocumentError)
            } else {
                toast.success(t(tokens.documents.messages.documentUpdated));
                dispatch(getDocuments(combineRequestBody()));
            }
            setUpdateClick(false);
        }
    }, [updateDocumentError, isLoading]);
 
    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (deleteDocumentError) {
                toast.error(deleteDocumentError)
            } else {
                filters.tab === "Active" 
                ? toast.success(t(tokens.documents.messages.documentRemoved))
                : toast.success(t(tokens.documents.messages.documentRemovedHard));
                dispatch(getDocuments(combineRequestBody()));
            }
            setDeleteClick(false);
            setDocumentId(0);
        }
    }, [deleteDocumentError, isLoading]);

    const handleSearchDocumentText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const onSearchDocument = (value: string) => {
        const query = value ? value : '';
        if (query.length !== 1) {
            updateFilters((prevState) => ({ ...prevState, query, page: 1 }));
            scrollTopList();
        }
    };

    const { debouncedWrapper: debouncedHandleDocumentSeacrh } = useDebounce(onSearchDocument, 500);

    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        updateFilters((prevState) => ({ ...prevState, tab, page: 1 }));
        scrollTopList();
    };

    const changeType = useCallback((type: any) => {
        updateFilters((prevState) => ({ ...prevState, page: 1, type }));
        scrollTopList();
    }, [updateFilters]);

    const changeStatus = useCallback((status: any) => {
        updateFilters((prevState) => ({ ...prevState, page: 1, status }));
        scrollTopList();
    }, [updateFilters]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [updateFilters]);

    const handleChangeCategory = useCallback((category: any) => {
        updateFilters((prevState) => ({ ...prevState, page: 1, category }));
        category === null && dispatch(getCategoriesWithoutTree());
        scrollTopList();
    }, [updateFilters]);

    const changeCategoryText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleCategorySeacrh(event.target.value);
    };

    const onSearchCategory = (value: string) => {
        if (value.length) {
            // dispatch(getCategories({ name: value, isHidden: false }));
            dispatch(getCategoriesWithoutTree({ name: value }));
        } else {
            dispatch(getCategoriesWithoutTree());
        }
    };

    const { debouncedWrapper: debouncedHandleCategorySeacrh } = useDebounce(onSearchCategory, 700);

    const handleDateChange = useCallback((date: Date | null): void => {
        const currentDate = date?.valueOf() || null;
        updateFilters((prevState) => ({ ...prevState, page: 1, date: currentDate }));
        scrollTopList();
    }, [updateFilters]);

    const resetFilters = useCallback((): void => {
        updateFilters((prevState) => ({ ...prevState, page: 1, rowsPerPage: 30, category: null, type: null, status: null, initiator: null, date: null }));
        scrollTopList();
    }, [updateFilters]);

    const handleStatus = (id: number, status: string) => {
        const documentStatus = status === 'Hidden' ? 'Available' : 'Hidden';
        // const newDocumentsList = documentsList.map(document => document.id === id ? { ...document, status: documentStatus } : document);
        // dispatch(setDocuments({documents: newDocumentsList, count: documentsCount}));

        dispatch(updateDocument({ body: { id, status: documentStatus } }));
        setUpdateClick(true);
    };
 
    const handleDelete = (id: number, mode?: string) => {
        if (!mode) {
            dispatch(deleteDocument({ id }));
            setDocumentId(id);
            setDeleteClick(true);
        }else{
            dispatch(deleteDocumentHard({ id }));
            setDocumentId(id);
            setDeleteClick(true);
        }
    };

    const handleUploadReport = (id: number, name: string) => {
        dispatch(uploadReport({ id, name }));
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        updateFilters((prevState) => ({ ...prevState, page: 1, initiator: node }));
        scrollTopList();
    };

    const onChangeInitiator = () => {
        if (filters.initiator) {
            updateFilters((prevState) => ({ ...prevState, page: 1, initiator: null }));
        }
    };

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    return {
        filters, searchText, handleTabsChange, handleSearchDocumentText, handleChangeCategory, changeType, changeStatus, handlePageChange,
        documentsList, categoryList, orgStructure, changeCategoryText, handleDateChange, pageAmount, onChangeInitiator, handleUploadReport,
        handleStatus, resetFilters, handleDelete, onSelectInitiator, deleteClick, documentId, isProccessDocument, scrollRef
    }
};