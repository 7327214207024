import { Box, CircularProgress, IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC, useCallback, useMemo } from "react";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../../locales/tokens";
import { Link } from "react-router-dom";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTranslation } from "react-i18next";

interface IProps {
    document: any;
    isProcess: boolean;
    documentId: number;
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number, mode?: string) => void;
    handleUpload: (id: number, name: string) => void;
};

export const DocumentTableItem: FC<IProps> = ({ document, handleStatus, handleDelete, handleUpload, isProcess, documentId }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const formattedDate = dayjs(document.updatedAt).format('DD.MM.YYYY');
    const changeStatus = useCallback(() => handleStatus(document.id, document.status), [document.id, document.status]);
    const deleteDocument = useCallback(() => {
        if (document.deleted_at) {
            handleDelete(document.id, 'hard');
        } else {
            handleDelete(document.id);
        }
    }, [document.id]); 
    const uploadReport = useCallback(() => handleUpload(document.id, document.name), [document.id, document.name]);

    return (
        <TableRow hover key={document.id}>
            <TableCell align="left" width='50%' sx={styles.cell}>
                <Typography variant="body2">{document.name}</Typography>
            </TableCell>
            <TableCell align="center" width='25%' sx={styles.cell}>
                <Typography variant="body2">
                    {formattedDate}
                </Typography>
            </TableCell>
            <TableCell align="right" width='25%' sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <IconButton size="small" onClick={uploadReport}>
                        <Tooltip title={t(tokens.documents.uploadReport)}>
                            <SvgIcon style={styles.svgIcon}><SimCardDownloadOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!document.deleted_at &&
                        <IconButton size="small" onClick={changeStatus}>
                            {document.status === 'Hidden' ?
                                <Tooltip title={t(tokens.documents.notPublish)}>
                                    <SvgIcon style={styles.svgIcon}><VisibilityOffOutlinedIcon /></SvgIcon>
                                </Tooltip>
                                :
                                <Tooltip title={t(tokens.documents.publish)} >
                                    <SvgIcon style={styles.svgIcon}><VisibilityOutlinedIcon /></SvgIcon>
                                </Tooltip>
                            }
                        </IconButton>}
                    <IconButton size="small" component={Link} to={`/content-document-edit/${document.id}`}>
                        <Tooltip title={document.deleted_at ? t(tokens.common.watch) : t(tokens.common.edit)}>
                            <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!document.deleted_at ? (
                        isProcess && documentId === document.id ? (
                            <Box px={1}><CircularProgress color="primary" size={20} /></Box>
                        ) : (
                            <IconButton size="small" onClick={deleteDocument}>
                                <Tooltip title={t(tokens.common.delete)}>
                                    <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                                </Tooltip>
                            </IconButton>
                        )
                    ) : ( 
                        isProcess && documentId === document.id ? (
                            <Box px={1}><CircularProgress color="primary" size={20} /></Box>
                        ) : (
                            <IconButton size="small" onClick={deleteDocument}>
                                <Tooltip title={t(tokens.common.deleteHard)}>
                                    <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                                </Tooltip>
                            </IconButton>
                        )
                    )}
                </Stack>
            </TableCell>
        </TableRow>
    )
};