import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../../../store/role/useCases/getRoles/action";
import { selectRole } from "../../../../store/role/repository/selector";
import { setRoleError } from "../../../../store/role/repository/slice";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { deleteRole } from "../../../../store/role/useCases/deleteRole/action";
import { getPermissions } from "../../../../store/permission/useCases/getPermissions/action";

export const useRolesList = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<'ARCHIVE' | 'ACTIVE'>('ACTIVE');
    const dispatch = useDispatch();
    const [isClick, setIsClick] = useState(false);
    const { roleList, isRoleLoading, roleError } = useSelector(selectRole);

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getPermissions());
    }, []);

    useEffect(() => {
        if (!isRoleLoading && isClick) {
            if (roleError) {
                toast.error(roleError)
                dispatch(setRoleError(null));
            } else {
                activeTab === 'ACTIVE' 
                ? toast.success(t(tokens.roles.messages.roleRemoved))
                : toast.success(t(tokens.roles.messages.roleRemovedHard));
            }
            setIsClick(false);
        };
    }, [isRoleLoading]);

    const roles = useMemo(() => {
        if (activeTab === 'ACTIVE') {
            return roleList.filter(role => !role.deleted_at);
        } else {
            return roleList.filter(role => !!role.deleted_at);
        };
    }, [activeTab, roleList]);

    const onToggleTab = () => {
        setActiveTab(prev => prev === 'ACTIVE' ? 'ARCHIVE' : 'ACTIVE');
    };

    const onDeleteRole = (roleId: number, mode?: string) => {
        if (roleId && !mode) {
            setIsClick(true);
            dispatch(deleteRole({ id: Number(roleId) }));
        } else {
            setIsClick(true);
            dispatch(deleteRole({ id: Number(roleId), mode }));
        };
    };

    return { activeTab, roles, isRoleLoading, onToggleTab, onDeleteRole };
};