import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { getProducts } from "../../../../store/product/useCases/getProducts/action";
import { selectProduct } from "../../../../store/product/repository/selector";
import { setProductError, setProductList } from "../../../../store/product/repository/slice";
import { PRODUCTS_PER_PAGE } from "../../../../store/product/useCases/getProducts/worker";
import { deleteProduct } from "../../../../store/product/useCases/deleteProduct/action";
import { updateProduct } from "../../../../store/product/useCases/updateProduct/action";
import { tokens } from "../../../../locales/tokens";

export const useProductsList = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState<'ARCHIVE' | 'ACTIVE'>('ACTIVE');
    const dispatch = useDispatch();
    const [isUpdateClick, setIsUpdateClick] = useState(false);
    const [isDeleteClick, setIsDeleteClick] = useState(false);
    const [isRequestList, setIsRequestList] = useState(false);
    const [page, setPage] = useState(1)
    const { isProductLoading, productList, productCount, productError } = useSelector(selectProduct);
    const scrollRef = useRef<HTMLDivElement>(null);

    const pageAmount = useMemo(() => {
        const count = productCount / PRODUCTS_PER_PAGE;
        return count > 1 ? Math.ceil(count) : 1;
    }, [productCount]);

    useEffect(() => {
        dispatch(getProducts({ page, isDeleted: activeTab === 'ACTIVE' ? false : true }));
        dispatch(setProductError(null));
    }, [activeTab]);

    useEffect(() => {
        if (!isProductLoading && isUpdateClick) {
            if (productError) {
                toast.error(productError)
                dispatch(setProductError(null));
            } else {
                toast.success(t(tokens.shop.messages.updated));
                dispatch(getProducts({ page, isDeleted: activeTab === 'ACTIVE' ? false : true }));
            }
            setIsUpdateClick(false);
        };
    }, [isProductLoading]);

    useEffect(() => {
        if (!isProductLoading && isDeleteClick) {
            if (productError) {
                toast.error(productError);
                dispatch(setProductError(null));
            } else {
                dispatch(getProducts({ page, isDeleted: activeTab === 'ACTIVE' ? false : true }));
                activeTab === 'ACTIVE' 
                ? toast.success(t(tokens.shop.messages.removed))
                : toast.success(t(tokens.shop.messages.removedHard))
            }
            setIsDeleteClick(false);
        };
    }, [isProductLoading]);

    useEffect(() => {
        if (!isProductLoading && isRequestList) {
            if (productError) {
                toast.error(productError)
                dispatch(setProductError(null));
            }
            setIsRequestList(false);
        };
    }, [isProductLoading]);

    const onToggleTab = () => {
        setIsRequestList(true);
        setPage(1);
        setActiveTab(prev => prev === 'ACTIVE' ? 'ARCHIVE' : 'ACTIVE');
    };

    const onChangePage = (_: any, selectedPage: number) => {
        setIsRequestList(true);
        setPage(selectedPage);
        dispatch(getProducts({ page: selectedPage, isDeleted: activeTab === 'ACTIVE' ? false : true }));
    };

    const onChangeProductVisibility = (productId: number, isHidden: boolean) => {
        if (productId) {
            setIsUpdateClick(true);
            // const updatedProducts = productList.map(product => product.id === productId ? { ...product, is_hidden: isHidden } : product);
            // dispatch(setProductList({ rows: updatedProducts, count: productCount }));
            dispatch(updateProduct({ product_id: Number(productId), is_hidden: isHidden }));
        } else {
            toast.error('Something went wrong');
        };
    };

    const onDeleteProduct = (productId: number, mode?: string) => {
        if (productId && !mode) {
            setIsDeleteClick(true);
            dispatch(deleteProduct({ id: Number(productId) }));
        } else {
            setIsDeleteClick(true);
            dispatch(deleteProduct({ id: Number(productId), mode }));
        };
    };

    return { activeTab, isRequestList, productList, page, pageAmount, isProductLoading, onChangePage, onToggleTab, onDeleteProduct, onChangeProductVisibility, scrollRef };
};